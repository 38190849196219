var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"filters"},[_c('i-select',{staticClass:"type-select",staticStyle:{"width":"200px"},model:{value:(_vm.displayType),callback:function ($$v) {_vm.displayType=$$v},expression:"displayType"}},[_c('i-option',{attrs:{"value":"dmtGraph"}},[_vm._v("DMT voortgang")]),_c('i-option',{attrs:{"value":"dmt"}},[_vm._v("DMT resultaten")]),_c('i-option',{attrs:{"value":"avi"}},[_vm._v("AVI resultaten")]),_c('i-option',{attrs:{"value":"misc"}},[_vm._v("Overige resultaten")])],1),_c('sb-from-to-input',{attrs:{"default-period":28},model:{value:(_vm.fromTo),callback:function ($$v) {_vm.fromTo=$$v},expression:"fromTo"}})],1),_c('div',{staticClass:"chart-container",style:({
      height: ['avi', 'dmt', 'misc'].includes(_vm.displayType)
        ? 'auto'
        : undefined,
    })},[(
        _vm.displayType === 'dmtGraph' &&
        _vm.$apollo.queries.intakeTests.loading === false
      )?_c('chart',{attrs:{"options":_vm.dmtChartOptions}}):_vm._e(),(_vm.displayType === 'dmt' && _vm.dmtResults)?_c('sb-table',{attrs:{"config":_vm.dmtTableConfig,"data":_vm.dmtResults,"with-navigation":false}}):_vm._e(),(_vm.displayType === 'avi' && _vm.aviResults)?_c('sb-table',{attrs:{"config":_vm.aviTableConfig,"data":_vm.aviResults,"with-navigation":false}}):_vm._e(),(_vm.displayType === 'misc' && _vm.miscResults)?_c('sb-table',{attrs:{"config":_vm.miscTableConfig,"data":_vm.miscResults,"with-navigation":false}}):_vm._e(),(_vm.$apollo.queries.intakeTests.loading)?_c('sb-loading',{attrs:{"position":"absolute","width":"100%","height":"100%","delay":0}}):_vm._e(),_c('sb-spacer',{attrs:{"height":"60"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }